<template>
    <div class="menu-mobile-container">
        <div class="collapse-menu">
            <div v-for="item in menu">
                <template v-if="typeof item.subitems === 'object' && item.subitems.length">
                    <a :href="item.href" :target="item.target" v-b-toggle="'mobile-menu' + item.id" @click.prevent="" class="collapse-menu-btn collapse-menu-name w-100 d-flex justify-content-between">
                        <div>{{item.name}}</div>
                        <div>
                            <svg class="collapse-menu-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="15" viewBox="0 0 51.431 73">
                                <defs>
                                    <filter id="Shape" x="0" y="0" width="51.431" height="73" filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha"/>
                                        <feGaussianBlur stdDeviation="3" result="blur"/>
                                        <feFlood flood-opacity="0.161"/>
                                        <feComposite operator="in" in2="blur"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <g transform="matrix(1, 0, 0, 1, 0, 0)">
                                    <path d="M1.737,10.125a5.931,5.931,0,0,1,8.388-8.388L31.694,23.306a5.931,5.931,0,0,1,0,8.388L10.125,53.263a5.931,5.931,0,1,1-8.388-8.388L19.112,27.5Z"/>
                                </g>
                            </svg>
                        </div>
                    </a>
                    <b-collapse v-for="subitem in item.subitems" :id="'mobile-menu' + item.id" :key="subitem.id">
                        <div v-if="!subitem.subitems.length">
                            <a :href="subitem.href" :target="subitem.target" @click="lgpdCheck(subitem.href, $event)" class="collapse-menu-btn level2 collapse-menu-name w-100 d-flex justify-content-between">
                                {{ subitem.name }}
                            </a>
                        </div>
                        <b-collapse v-for="subitem2 in subitem.subitems" :id="'mobile-menu' + item.id" :key="subitem2.id">
                            <b-button v-b-toggle="'mobile-menu' + subitem2.id" class="collapse-menu-btn-second-level collapse-menu name w-100 d-flex justify-content-between">
                                <div>{{subitem2.name}}</div>
                                <div>
                                    <svg class="collapse-menu-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="15" viewBox="0 0 51.431 73">
                                        <defs>
                                            <filter id="Shape" x="0" y="0" width="51.431" height="73" filterUnits="userSpaceOnUse">
                                                <feOffset dy="3" input="SourceAlpha"/>
                                                <feGaussianBlur stdDeviation="3" result="blur"/>
                                                <feFlood flood-opacity="0.161"/>
                                                <feComposite operator="in" in2="blur"/>
                                                <feComposite in="SourceGraphic"/>
                                            </filter>
                                        </defs>
                                        <g transform="matrix(1, 0, 0, 1, 0, 0)">
                                            <path d="M1.737,10.125a5.931,5.931,0,0,1,8.388-8.388L31.694,23.306a5.931,5.931,0,0,1,0,8.388L10.125,53.263a5.931,5.931,0,1,1-8.388-8.388L19.112,27.5Z"/>
                                        </g>
                                    </svg>
                                </div>
                            </b-button>
                        </b-collapse>
                    </b-collapse>
                </template>
                <template v-else>
                    <a :href="item.href" :target="item.target" @click="lgpdCheck(item.href, $event)" class="collapse-menu-btn collapse-menu-name w-100 d-flex justify-content-between">
                        <div>{{item.name}}</div>
                    </a>
                </template>
            </div>
        </div>
        <div class="px-4 py-3">
            <div v-html="$wdconfig.default.name" class="font-weight-bold mb-2"></div>
            <div>Fone: {{ $wdconfig.default.phone }}</div>
            <div v-html="address"></div>
            <div>{{ $wdconfig.default.email }}</div>
            <div>{{ $wdconfig.default.text_service_hours }}</div>
        </div>
        <div class="px-4 py-3">
            <a :href="$wdconfig.default.social.whatsapp.link" class="btn-whatsapp" target="_blank" rel="nofollow">
                <svg version="1.1" fill="#FFF" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="20" height="20">
                            <g>
                                <g>
                                    <path d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                        C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                        c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                        c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                        c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                        c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                        c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                        c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                        c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"/>
                                </g>
                            </g>
                        </svg>
                <span v-html="$wdconfig.default.social.whatsapp.title"></span>
            </a>
        </div>
        <div class="px-3 py-2">
            <ul class="d-flex flex-wrap mb-1 list-unstyled">
                <li v-for="(social, key) in getListSocials" :key="key" class="mr-2 mb-2 social-network-item">
                    <a :href="social.link" :title="social.title" target="_blank" rel="nofollow"><img :src="social.image" :alt="social.name" :title="social.title" width="24" height="24" class="img-fluid"></a>
                </li>
            </ul>
        </div>
        <div class="payments_info py-4">
            <div v-if="cards.length" class="mb-5">
                <div class="title mb-2 text-uppercase text-center"> Pague com</div>
                <div class="d-flex flex-wrap justify-content-center align-items-center brands">
                    <div v-for="item in cards">
                        <img :src="imageUrl(item.image1)" :alt="item.name" :title="item.name" class="m-2">
                    </div>
                </div>
            </div>
            <div v-if="certificates.length" class="mb-3">
                <div class="title mb-2 text-uppercase text-center"> Certificado e segurança</div>
                <div class="d-flex flex-wrap justify-content-center align-items-center security">
                    <div v-for="item in certificates">
                        <template v-if="item.link">
                            <a :href="item.link" target="_blank">
                                <img :src="item.image" :alt="item.name" :title="item.name" class="img-fluid m-2">
                            </a>
                        </template>
                        <template v-else>
                            <img :src="item.image" :alt="item.name" :title="item.name" class="img-fluid m-2">
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="all-rights-reserved-mobile d-lg-none">
            <b-container>
                <div class="d-flex justify-content-between align-items-center pt-3 pb-3 flex-column flex-md-row">
                    <div class="social-reason text-uppercase text-center"> {{ $wdconfig.default.copyright }}</div>
                    <div class="my-3">
                        <a href="https://www.wdshop.com.br/" target="_blank" :rel="$route.path === '/' ? '' : 'nofollow'" title="WD House - Somos digitais">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1276.66 251.28" width="100" height="19" class="wdhouse">
                                <g>
                                    <g>
                                        <path d="M180.55,70.89v113a21,21,0,0,1-1.82,8.7,23.72,23.72,0,0,1-4.89,7.12,22.58,22.58,0,0,1-16,6.54,21.84,21.84,0,0,1-8.7-1.74,22.47,22.47,0,0,1-7.12-4.8,23.63,23.63,0,0,1-4.89-7.12,20.94,20.94,0,0,1-1.82-8.7v-113H90.27v113a21.82,21.82,0,0,1-6.63,15.82c-.24.23-.49.43-.73.65,6,14.49,18.35,24.64,28.5,34.73.4-.35.82-.65,1.22-1a66.77,66.77,0,0,0,21,12.84,67.75,67.75,0,0,0,24.18,4.39,66.27,66.27,0,0,0,26.42-5.3,68.85,68.85,0,0,0,21.53-14.41,67.2,67.2,0,0,0,14.49-21.45,65.82,65.82,0,0,0,5.3-26.25v-113Z"/>
                                        <path style="opacity: 0.7" d="M82.91,200.33a23.31,23.31,0,0,1-6.55,4.15,22.45,22.45,0,0,1-8.78,1.74,21.84,21.84,0,0,1-8.7-1.74A22.5,22.5,0,0,1,47,192.56a21.83,21.83,0,0,1-1.74-8.7v-113H0v113a65.77,65.77,0,0,0,5.3,26.25,67.16,67.16,0,0,0,14.49,21.45A69.06,69.06,0,0,0,41.24,246a65.66,65.66,0,0,0,26.34,5.3,67.56,67.56,0,0,0,24.27-4.39,64.92,64.92,0,0,0,19.57-11.82C101.27,225,88.91,214.82,82.91,200.33Z"/>
                                        <path d="M402.48,248H391.55L374,223.61a145.05,145.05,0,0,1-13.67,10.85,101,101,0,0,1-15.16,8.78,93,93,0,0,1-16.32,5.88,67.89,67.89,0,0,1-17,2.15,89.32,89.32,0,0,1-64-26.59A91.84,91.84,0,0,1,228.39,195a96.39,96.39,0,0,1-7.12-37.35,96.57,96.57,0,0,1,7.12-37.27,92.65,92.65,0,0,1,19.46-29.82,89.85,89.85,0,0,1,28.82-19.71,87.78,87.78,0,0,1,35.2-7.12,80.14,80.14,0,0,1,12.34,1A64.32,64.32,0,0,1,336.64,68,51.56,51.56,0,0,1,348,73.88a34.52,34.52,0,0,1,8.94,8.94V0h45.55Zm-45.55-90.27a47,47,0,0,0-3.56-18,51.73,51.73,0,0,0-9.69-15.4,48.2,48.2,0,0,0-14.33-10.77,38.9,38.9,0,0,0-17.48-4.06,46.23,46.23,0,0,0-17.48,3.31,41.17,41.17,0,0,0-14.25,9.52,44.55,44.55,0,0,0-9.52,15.16,55.41,55.41,0,0,0-3.48,20.21,50.48,50.48,0,0,0,13,34,44.38,44.38,0,0,0,14.25,10.44,41,41,0,0,0,17.48,3.81,38.86,38.86,0,0,0,17.48-4.06,48.16,48.16,0,0,0,14.33-10.77,51.73,51.73,0,0,0,9.69-15.4A47,47,0,0,0,356.93,157.69Z"/>
                                        <path d="M482.15,64.67V0H436.93V85.45c15-6.28,33-9.75,45.32-20.85Z"/>
                                        <path d="M589.24,113a81.53,81.53,0,0,0-16.9-25.34,78.38,78.38,0,0,0-25.09-17.06,77,77,0,0,0-30.81-6.21,61.2,61.2,0,0,0-17.56,2.57,52.78,52.78,0,0,0-16.63,8.63c-12.33,11.1-30.34,14.56-45.32,20.85V245.23h45.22V143.86a33.2,33.2,0,0,1,2.65-13.17,34.12,34.12,0,0,1,31.64-20.79,32.63,32.63,0,0,1,13.09,2.65,34.14,34.14,0,0,1,18.05,18.14,33.24,33.24,0,0,1,2.65,13.17h-.33V245.23h45.55V143.86A77.59,77.59,0,0,0,589.24,113Z"/>
                                        <path d="M779.87,157.69a95.57,95.57,0,0,1-7.12,37,92.6,92.6,0,0,1-19.38,29.65,90.68,90.68,0,0,1-28.74,19.71,90,90,0,0,1-70.56,0,90.79,90.79,0,0,1-48.28-49.36,95.52,95.52,0,0,1-7.12-37,97,97,0,0,1,7.12-37.43,91.67,91.67,0,0,1,19.46-29.73,90.52,90.52,0,0,1,28.82-19.63,87.78,87.78,0,0,1,35.2-7.12,92.91,92.91,0,0,1,35.36,6.71,85.49,85.49,0,0,1,28.74,19,90.29,90.29,0,0,1,19.38,29.65Q779.87,136.49,779.87,157.69Zm-45.55,0a52.67,52.67,0,0,0-3.56-19.79,47.11,47.11,0,0,0-9.69-15.24,41.93,41.93,0,0,0-14.33-9.77,46.66,46.66,0,0,0-35,0,41.07,41.07,0,0,0-14.25,9.77A46.62,46.62,0,0,0,648,137.9a53.78,53.78,0,0,0-3.48,19.79,51.24,51.24,0,0,0,3.48,19A47.77,47.77,0,0,0,657.55,192a44.55,44.55,0,0,0,14.25,10.19,41.73,41.73,0,0,0,17.48,3.73,44.28,44.28,0,0,0,17.48-3.48,43.48,43.48,0,0,0,14.33-9.77,45.91,45.91,0,0,0,9.69-15.24A52.84,52.84,0,0,0,734.32,157.69Z"/>
                                        <path d="M916.09,227.26a121.88,121.88,0,0,1-11.68,9.36,87.82,87.82,0,0,1-13,7.62,80.53,80.53,0,0,1-14,5.13,57.16,57.16,0,0,1-14.66,1.9,81.8,81.8,0,0,1-30.81-5.8A74.86,74.86,0,0,1,789.63,203q-6.21-15.32-6.21-34.37V70.56h45.05v98.06a43.9,43.9,0,0,0,2.73,16,35,35,0,0,0,7.37,11.84,30.8,30.8,0,0,0,10.85,7.29,35.57,35.57,0,0,0,13.33,2.48A28.15,28.15,0,0,0,875.93,203a36.94,36.94,0,0,0,10.85-8.53,40.94,40.94,0,0,0,7.29-12,37.36,37.36,0,0,0,2.65-13.83V70.56h45.22V248H931Z"/>
                                        <path d="M1054.63,248H951.27V202.91h103.36a11.31,11.31,0,0,0,11.26-11.26q0-4.8-3.31-6.29a19.29,19.29,0,0,0-8-1.49h-47a54.56,54.56,0,0,1-22-4.47,56.64,56.64,0,0,1-29.9-30.15,57.48,57.48,0,0,1,0-44.06,55.83,55.83,0,0,1,29.9-29.9,55.46,55.46,0,0,1,22-4.39h91.6v45.05h-91.6a11.31,11.31,0,0,0-11.26,11.26,11.3,11.3,0,0,0,3.31,8.2,10.71,10.71,0,0,0,8,3.4h47a61.21,61.21,0,0,1,21.86,3.89,55.32,55.32,0,0,1,17.89,10.85,51.29,51.29,0,0,1,12.09,16.73,50.74,50.74,0,0,1,4.47,21.37,54,54,0,0,1-4.47,21.95,58.83,58.83,0,0,1-12.09,17.89,55.65,55.65,0,0,1-17.89,12.09A55,55,0,0,1,1054.63,248Z"/>
                                        <path d="M1191.85,204.57a25.72,25.72,0,0,0,5.3,1.08q2.65.25,5.3.25a44.5,44.5,0,0,0,12.75-1.82,46,46,0,0,0,11.51-5.22,42.87,42.87,0,0,0,9.61-8.28,43.52,43.52,0,0,0,6.87-10.85L1276.33,213a89.23,89.23,0,0,1-14.49,16.07,91.38,91.38,0,0,1-17.72,12.09,88.53,88.53,0,0,1-20,7.54,90.94,90.94,0,0,1-21.62,2.57,89.55,89.55,0,0,1-64-26.34A90.9,90.9,0,0,1,1119,195.37a97.26,97.26,0,0,1-7.12-37.68,100.48,100.48,0,0,1,7.12-38.43,86.84,86.84,0,0,1,48.28-48.7,91.5,91.5,0,0,1,35.2-6.79,89.19,89.19,0,0,1,21.7,2.65,90.82,90.82,0,0,1,37.93,19.79,90,90,0,0,1,14.58,16.15ZM1215,111.31a26.14,26.14,0,0,0-6.21-1.49,59.84,59.84,0,0,0-6.38-.33,45.22,45.22,0,0,0-17.48,3.4,41.63,41.63,0,0,0-14.25,9.69,45.66,45.66,0,0,0-9.52,15.16,54.26,54.26,0,0,0-3.48,20q0,2.48.25,5.63t.83,6.38q.58,3.23,1.41,6.21a23,23,0,0,0,2.15,5.3Z"/>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </b-container>
        </div>
    </div>
</template>

<script>
    import socials from "~/mixins/socials";
    import utils from "~/mixins/utils";
    import lgpd from "~/mixins/lgpd";

    export default {
        mixins: [
            socials,
            utils,
            lgpd
        ],
        data() {
            return {
                menu: [],
                cards: []
            }
        },
        computed: {
            address() {
                let a = this.$wdconfig.default.address;
                if (a.street && a.number && a.city && a.neighborhood && a.state) {
                    return a['street'] + ', ' + a['number'] + ' ' + a['complement'] + '<br>'
                        + a['neighborhood'] + ', ' + a['city'] + '/' + a['state'];
                }
                return ''
            },
            certificates() {
                return [
                    {
                        name: 'Google',
                        link: process.browser ? `https://transparencyreport.google.com/safe-browsing/search?hl=pt-PT&url=${window.location.protocol}//${window.location.hostname}` : null,
                        image: '/img/certificates/security-site.png'
                    }, {
                        name: 'Site Seguro',
                        link: null,
                        image: '/img/certificates/security-site-2.png'
                    }, {
                        name: 'letsEncrypt',
                        link: null,
                        image: '/img/certificates/letsEncrypt.png'
                    }, {
                        name: 'SSL',
                        link: null,
                        image: '/img/certificates/selo-ssl-blindado.png'
                    }
                ]
            }
        },
        async fetch() {
            try {
                this.menu = [].concat(this.$store.state.menu_site, this.$store.state.menu_footer)
            } catch (e) {
                console.error(e)
            }

            await this.$axios.$get(`/api/v1/cms/?category_code=4&limit=100`).then((res) => {
                if (res.status === 'success') {
                    this.cards = res.data
                }
            }).catch((e) => {
                console.error(e)
            })
        }
    }
</script>
